import Swiper from 'swiper';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css/bundle';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/a11y';

// const btnPrev = document.querySelector('[data-action="swiper-previous"]');
// const btnNext = document.querySelector('[data-action="swiper-next"]');
// const btnScrollToNextBlock = document.querySelectorAll('[data-action="scroll-to-next"]');

// function registerButtonEvents() {
//   [...btnScrollToNextBlock].forEach((btn) => {
//     btn.addEventListener('click', () => {
//       const closest = btn.closest('.b-carousel');
//       let nextSegment = false;

//       if (closest) { nextSegment = closest.nextElementSibling; }
//       if (nextSegment) { nextSegment.scrollIntoView({ behavior: 'smooth' }); }
//     });
//   });
// }

// function toggleVideos(obj) {
//   const { slides, videos } = obj;
//   const currentIndex = obj.swiper.activeIndex;
//   const video = slides[currentIndex].querySelector('video');

//   [...videos].forEach((v) => { v.pause(); });
//   if (video) { video.play(); }
// }

function mountCarousels(carouselEls) {
  const sliders = [];

  Swiper.use([Navigation, Pagination, A11y, Autoplay]);

  [...carouselEls].forEach((carouselEl, idx) => {
    const slides = carouselEl.querySelectorAll('.swiper-slide');
    const videos = carouselEl.querySelectorAll('.swiper-slide video');
    //const btnPlayPause = carouselEl.querySelector('.swiper-autoplay-toggle');

    const swiper = new Swiper(carouselEl.querySelector('.swiper-container'), {
      // simulateTouch: false,
      speed: 500,
      slidesPerView: 1,
      spaceBetween: 20,
      //loop: true,
      centeredSlides: false,
      watchSlidesProgress: true,
      /*
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
        bulletElement: 'button',
      },
      */
      breakpoints: {
        // when window width is >= 320px
        768: {
          slidesPerView: 1,
          spaceBetween: 30,
          centeredSlides: false,
          watchSlidesProgress: true,
        },
        960: {
          slidesPerView: 2,
          spaceBetween: 30,
          centeredSlides: true,
          watchSlidesProgress: true,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 30,
          centeredSlides: false,
          watchSlidesProgress: true,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next-lhca',
        prevEl: '.swiper-button-prev-lhca',
      },
      a11y: {
        paginationBulletMessage: document.documentElement.lang === 'fi' ? 'Siirry diaan {{index}}' : 'Go to slide {{index}}',
      },
      // autoHeight: true,

    });

    sliders[idx] = {
      swiper,
      slides,
      videos,
    };
  });
}

function init() {
  const carouselEls = document.querySelectorAll('.b-image-carousel');
  if (carouselEls.length) {
    mountCarousels(carouselEls);
    // if (btnScrollToNextBlock) {
    //   registerButtonEvents();
    // }
  }
}

export default {
  init,
};
