//import { MotionScroll } from 'motion-scroll';
//import { MotionScroll } from '../../assets/scripts/lib/motionscroll.min.js';
import Rellax from 'rellax';

function init() {


  // Branding
  //var rx1       = new Rellax('.rx--1');

  // First two bricks
  //var rx1b      = new Rellax('.rx--1b');
  //var rx2c      = new Rellax('.rx--2c');


  var rx2d      = new Rellax('.rx--2d');
  var rx3       = new Rellax('.rx--3');

  var rxae1a    = new Rellax('.rx--ae1-a');
  var rxae1b    = new Rellax('.rx--ae1-b');

  // Cars
  // https://github.com/dixonandmoe/rellax/issues/186#issuecomment-585209406

  /*
  var rxae2    = new Rellax('.rx--ae2', {
    horizontal: true,
    breakpoints:[480, 768, 1440]
  });
  */

  var rxae2    = new Rellax('.rx--ae2', {
    horizontal: true,
    horizontalSpeed: 20,
    breakpoints:[480, 768, 1440]
  });

  var rxae2_mobile    = new Rellax('.rx--ae2-mobile', {
    horizontal: true,
    horizontalSpeed: 2,
  });

  /*
  const mediaQuery = window.matchMedia('(max-width: 480px)');

  if ( !mediaQuery.matches ) {
    var rxae2    = new Rellax('.rx--ae2', {
      horizontal: true,
      horizontalSpeed: 20,
      breakpoints:[480, 768, 1440]
    });
  }
  else {
  }
  */


  var rxae3    = new Rellax('.rx--ae3');
  var rxae4a  = new Rellax('.rx--ae4-a');
  var rx4b    = new Rellax('.rx--4b');
  var rx6     = new Rellax('.rx--6');


  //var rx2a      = new Rellax('.rx--2a');
  //var rx2b      = new Rellax('.rx--2b');

  // https://github.com/dixonandmoe/rellax/issues/120#issuecomment-418052546
  /*
  var rx4       = new Rellax('.rx--4', {
    relativeToWrapper: true,
    wrapper: '.section--4'
  });
  */

  //var rxae4b    = new Rellax('.rx--ae4-b');
  //var rx5a     = new Rellax('.rx--5a');
  //console.log(rellax);

}

export default {
  init,
};
