import googleMapsApiLoader from 'google-maps-api-loader';

let breakpointMql;

function setMapPosition(map, centerLatLng, offset) {
  if (breakpointMql.matches) {
    map.setCenter(centerLatLng);
    map.panBy(offset, 70);
  } else {
    map.setCenter(centerLatLng);
  }
}

function init() {
  const mapCanvasEls = document.querySelectorAll('.google-map__canvas') || [];

  if (!mapCanvasEls.length) {
    return;
  }

  const { apiKey } = mapCanvasEls[0].dataset;

  breakpointMql = window.matchMedia('(min-width: 960px)');

  googleMapsApiLoader({
    libraries: ['places'],
    apiKey,
  }).then((googleApi) => {
    mapCanvasEls.forEach((mapCanvasEl) => {
      let stylesArray = [];
      const markers = {};
      const { markerTitle } = mapCanvasEl.dataset;
      const centerLatLng = {
        lat: Number(mapCanvasEl.dataset.lat),
        lng: Number(mapCanvasEl.dataset.lng),
      };
      const addressBlockEl = mapCanvasEl.closest('.b-address');
      const addressContentEl = addressBlockEl.querySelector('.address__content-outer-container');
      const align = addressBlockEl.classList.contains('alignright') ? 'right' : 'left';
      const offset = addressContentEl.offsetWidth / 3 * (align === 'right' ? 1 : -1);

      try {
        stylesArray = JSON.parse(mapCanvasEl.parentElement.querySelector('.map__style-data').textContent);
      } catch (error) {
        // console.err(error);
      }

      const map = new googleApi.maps.Map(mapCanvasEl, {
        center: centerLatLng,
        zoom: 14,
        disableDefaultUI: true,
        gestureHandling: 'none',
        zoomControl: false,
      });

      setMapPosition(map, centerLatLng, offset);

      breakpointMql.addListener(() => {
        setMapPosition(map, centerLatLng, offset);
      });

      if (stylesArray.length) {
        const styledMapType = new googleApi.maps.StyledMapType(stylesArray, { name: 'fingersoft_map' });
        // Associate the styled map with the MapTypeId and set it to display.
        map.mapTypes.set('fingersoft_map', styledMapType);
        map.setMapTypeId('fingersoft_map');
      }

      markers[markerTitle] = new googleApi.maps.Marker({
        position: centerLatLng,
        title: markerTitle,
        icon: {
          url: `${window.location.origin}/app/themes/fingersoft/src/assets/images/google-map-marker.png`,
          size: new googleApi.maps.Size(116, 318),
          scaledSize: new googleApi.maps.Size(58, 159),
          anchor: new googleApi.maps.Point(36, 140),
        },
        map,
      });
    });
  }, (err) => {
    console.error(err);
  });
}

export default {
  init,
};
